import React from 'react';
import { Container, Navbar, Row, Col, Collapse } from 'react-bootstrap';

export class  Footer extends React.Component { 
    render() {
        return (
            <>
          <Navbar bg="dark" className="mt-5">    
            <Container className="text-light justify-content-md-center">  
                <Row>
                    <Col md="auto" className="mx-5">
                        <Row className="fs-4 fw-bold">Compression Plus</Row>                           
                        <Row>
                        <div>
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="bi bi-house-fill m-2"
                            viewBox="0 0 16 16"
                            >
                            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"></path>
                            <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"></path>
                            </svg>
                        Unit 321 – 4909 17 Ave SE                        
                        </div>
                        
                        
                        </Row>  
                        <Row>
                        <div>
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="bi bi-telephone-fill m-2"
                            viewBox="0 0 16 16"
                            >                           
                            </svg>
                            Calgary, AB T2A 0V5
                        </div>
                           </Row> 
                        <Row>
                        <div>
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="bi bi-telephone-fill m-2"
                            viewBox="0 0 16 16"
                            >
                            <path
                                fillRule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            />
                            </svg>
                            Phone : 587-968-1070
                        </div>
                        </Row>
                        <Row><div>
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="bi bi-house-fill m-2"
                            viewBox="0 0 16 16"
                            >
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                            </svg>Email: info@compressionplus.ca</div>
                        </Row>                                    
                    </Col>    
                    <Col className="mx-5"></Col>   
                    <Col className="mx-5"></Col>                                 
                    <Col xs="auto" sm="auto" className="mx-5 justify-content-start text-left">
                        <Row className="fs-4 fw-bold">
                        Business Hours
                        </Row> 
                        <Row>
                        <Col xs="auto" sm="auto" className='text-left mx-2'>
                            <Row>Mon:</Row>
                            <Row>Tue:</Row>
                            <Row>Wed:</Row>
                            <Row>Thu:</Row>   
                            <Row>Fri:</Row>
                            <Row>Sun:</Row>                   
                            <Row>Holidays:</Row>
                        </Col>
                        <Col xs="auto" sm="auto"  className='text-left mx-2'>
                            <Row>by appointment only</Row>
                            <Row>by appointment only</Row>
                            <Row>10-5</Row>
                            <Row>10-5</Row>   
                            <Row>10-5</Row>
                            <Row>10-3</Row>                   
                            <Row>Closed</Row>
                        </Col>
                        </Row>   
                    </Col>
                </Row>   
                          
            </Container>
            
         </Navbar>
         <div className="fixed-bottom">
            <a href="tel:587-968-1070" title='Call now 587-968-1070'>
            <div className="content-center">
                <div className="pulse">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFFFFF" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>                
                </div>                      
            </div>    
            </a>        
            </div>
         </>
        );
    }
}