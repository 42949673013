import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
// import * as backgroundImage from '../../public/images/product/calf-sleeve/1014.jpg'
//  const images = require.context('../../public/images', true);
import Parser from 'html-react-parser';
const PRODUCTS = [
    {
        id: "1",
        name: 'Cushion Sports: DS1082, DS1084 (15-20mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/cushion-sports/1082_1084.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/cushion-sports/1082_1084_2.jpg')
            }                 
        ],
        sku: 'DS1082, DS1084',
        color: 'Charcoal',
        size: 'Medium, Large',
        level: '15-20mmHg',
        strength: 'Mild Pressure',
        product_length: 'Knee High',
        composition: '63%Nylon 29%Cotton 8%Elastane',
        product_style: 'Cushion Sports',
        used: 'Tired, aching legs. Minor varicose veins. Minor leg, ankle, and foot swelling. Post surgery. All day sitting or standing, travel, recovery, pregnancy.',
        benefits: `<b>Cushion Sports </b>compression socks are designed to provide excellent support and improve blood circulation during physical activities. With <b>non-slip soles</b> and added cushioning on the bottom, they <b>increase grip and reduce foot pressure</b>, making them ideal for active use. Extra padding at the heel and ball of the foot adds comfort and protection.
<br/><br/>
Their snug fit helps <b>reduce muscle fatigue and swelling</b>, making them a top choice for athletes. Available in various styles, these socks combine functionality and style, offering both performance enhancement and faster recovery.
`
    },
    {
        id: "2",
        name: 'Cushion Sports: DS1086, DS1088 (15-20mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/cushion-sports/1086_1088.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/cushion-sports/1086_1088_2.jpg')
            }             
        ],
        sku: 'DS1086, DS1088',
        color: 'Black',
        size: 'Medium, Large',
        level: '15-20mmHg',
        strength: 'Mild Pressure',
        product_length: 'Knee High',
        composition: '63%Nylon 29%Cotton 8%Elastane',
        product_style: 'Cushion Sports',
        used: 'Tired, aching legs. Minor varicose veins. Minor leg, ankle, and foot swelling. Post surgery. All day sitting or standing, travel, recovery, pregnancy.',
        benefits: `<b>Cushion Sports</b> compression socks help support your feet and improve blood circulation during physical activities. They feature a sporty design with <b>zebra-like ridges</b> on the foot and heel, ensuring a secure fit while <b>protecting your soft tissues</b> from injury.
<br/><br/>
These socks cushion key areas, like the heel and ball of the foot, for added comfort and protection from impact. They use moisture-control fibers to keep your feet dry, provide arch compression for stability, and have heel lock technology to prevent slipping. These features <b>reduce muscle fatigue and swelling</b>, making these socks ideal for athletes who want better performance and faster recovery`
    },    
    {
        id: "3",
        name: 'Zipper Style: DS1154, DS 1156 (Black)  DS1164, DS1166  (Skin) (20-30mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/zipper-style/ds_1154_1156.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/zipper-style/ds_1154_1156_2.jpg')
            },            
            {
                id: "img3",
                url: require('../assets/product/socks-knee-high/zipper-style/ds_1164_ds_1166.jpg')
            },            
            {
                id: "img4",
                url: require('../assets/product/socks-knee-high/zipper-style/ds_1164_ds_1166_2.jpg')
            }              
        ],
        sku: 'DS1164, DS1166 (Skin) DS1154, DS1156 (Black)',
        color: 'Skin, Black',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Knee High',
        composition: '80% Nylon 20% Elastane',
        product_style: 'Zipper Style',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers',
        benefits: `
        Compression socks with a <b>Zipper style</b> are designed to provide the benefits of compression while being <b>easier to put on and take off</b>. These socks typically offer graduated compression that is tighter at the ankle and gradually decreases up the leg, which can help <b>improve circulation, reduce swelling, and alleviate pain</b>.
<br/><br/>
The addition of a zipper makes them more accessible, especially for individuals who may struggle with traditional compression socks. They are suitable for a wide range of activities and can be particularly beneficial for those who stand for long periods, travel frequently, or have certain medical conditions
        
        `
    },    
    {
        id: "4",
        name: 'Merino Wool: DS1062 (20-30mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/merino-wool/1062.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/merino-wool/1062_2.jpg')
            }     
        ],
        sku: 'DS1062',
        color: 'Gray',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Knee High',
        composition: '47% Merino Wool 31% Lyocell Tencel 15%Nylon 5%Spandex',
        product_style: 'Merino Wool',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
        <b>Merino Wool</b> compression socks are known for their outstanding qualities. The Merino Wool fibers provide excellent insulation, <b>keeping feet warm</b> even in cold conditions. Combining the natural advantages of merino wool with the benefits of compression technology. These socks help <b>improve circulation, reduce swelling, and provide comfort</b> during periods of inactivity or intense activity.
<br/><br/>
The merino wool ensures temperature regulation and moisture control, keeping feet dry and comfortable. Ideal for <b>daily wear, travel, or sports</b>, these socks offer a perfect balance of support, durability, and comfort, making them an excellent choice for various activities.

        `
    },
    {
        id: "5",
        name: 'Royal Pattern: DS1042 (20-30mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/royal-pattern/1042.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/royal-pattern/1042_2.jpg')
            }     
        ],
        sku: 'DS1042',
        color: 'Black',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Knee High',
        composition: '50% Nylon 45% Polyeser 5% Spandex',
        product_style: 'Royal Pattern',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       For anyone who stands for long hours or travels frequently, <b>Royal Pattern</b> compression socks are an excellent choice for leg comfort and support. These socks feature graduated compression levels of 20-30mmHg, <b>promoting better blood circulation and reducing swelling</b>.
<br/><br/>
In addition to their functional benefits, Royal Pattern socks are made from nearly 50% polyester materials that <b>resist bacteria, enhance durability, and dry quickly</b>. They keep your feet dry and comfortable throughout the day, making them suitable for extended wear. Whether you're a healthcare professional, an athlete, or simply in need of daily leg support, these stylish socks provide the perfect blend of comfort and functionality.

        `
    },
    {
        id: "6",
        name: 'Open Toe Style: DS1174 DS1176 (Black) DS1184, DS1186 (Skin) (20-30mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/open-toe/1174_1176.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/open-toe/1174_1176_2.jpg')
            },            
            {
                id: "img3",
                url: require('../assets/product/socks-knee-high/open-toe/1184_1186.jpg')
            },            
            {
                id: "img4",
                url: require('../assets/product/socks-knee-high/open-toe/1184_1186_2.jpg')
            }      
        ],
        sku: 'DS1184, DS1186 (Skin) DS1174 DS1176 (Black)',
        color: 'Skin, Black',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Knee High',
        composition: '80% Nylon 20% Elastane',
        product_style: 'Open Toe',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Open-Toe Knee high</b> medical support hoses are essential for anyone needing extra leg support. These unisex socks feature a durable knit design and provide graduated compression strength of 20-30 mmHg, enhancing circulation while <b>alleviating swelling, varicose veins, and sore muscles</b>.
<br/><br/>
Their soft top ensures comfort without constricting the leg, while a defined heel enhances fit. Perfect for <b>extensive sitting or standing, travel, pregnancy, or post-surgery recovery</b>, these toeless socks allow easy foot inspection and add versatility to your wardrobe. Enjoy the benefits of improved circulation and comfort with these reliable support hoses.

        `
    },
    {
        id: "7",
        name: 'Crew Length: DS1054, DS1056 (20-30mmHg)',
        image: [
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/crew-length/1054_1056.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/crew-length/1054_1056_2.jpg')
            },  
        ],
        sku: ': DS1054, DS1056',
        color: '',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Crew',
        composition: '92% Nylon 8% Elastane',
        product_style: 'Crew Sports',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Crew Sports Compression Socks</b> offer a range of benefits designed specifically for athletes. The durable materials wick away sweat and <b>dry quickly, keeping your feet comfortable and cool</b> even during intense activities. With a new <b>Locked-In Fit design</b>, these socks stay securely on your heel, eliminating the need for constant adjustments, while medium cushioning provides additional comfort and protection throughout your workouts.
<br/><br/>
Moreover, the built-in arch support helps reduce foot fatigue, enhancing your overall performance. The mesh panels on top of the foot improve breathability, ensuring your feet stay fresh and dry. Whether you’re running, cycling, or engaging in any athletic activity, Crew Sports Compression Socks are your perfect companion for maximizing comfort and performance.

        `
    },
    {
        id: "8",
        name: 'Crew Length: DS1094, DS1096 (20-30mmHg)',
        image: [            
        ],
        sku: 'DS1054, DS1056',
        color: 'Black',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Crew',
        composition: '92% Nylon 8% Elastane',
        product_style: 'Microfiber',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Crew Microfiber Compression Socks</b> offer unparalleled support and comfort, making them ideal for those on their feet throughout the day. Dynamic arch compression not only enhances stability and balance but also plays a vital role in maintaining proper foot alignment. This feature is essential for individuals engaged in prolonged standing or active sports, as it helps prevent discomfort and injuries.
<br/></br>
       In addition to their supportive design, these socks incorporate breathable mesh uppers that keep feet cool and dry, along with anti-microbial and anti-odor properties to maintain freshness all day. Their ability to enhance circulation further reduces fatigue, making <b>Crew Sports Compression Socks</b> an invaluable asset for work, athletic activities, or everyday wear.

        `
    },
    {
        id: "9",
        name: 'Calf Sleeve: DS1014 (Skin), DS1022 (Black) (20-30mmHg)',
        image: [ 
            {
                id: "img1",
                url: require('../assets/product/calf-sleeve/1014.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/calf-sleeve/1014_2.jpg')
            },
            {
                id: "img3",
                url: require('../assets/product/calf-sleeve/1022.jpg')
            }, 
            {
                id: "img4",
                url: require('../assets/product/calf-sleeve/1022_2.jpg')
            },          
        ],
        sku: 'DS1014 (Skin), DS1022 (Black) ',
        color: 'Skin, Black',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: '',
        composition: 'Skin: 80% Nylon 20% Elastane - Black: 90% Nylon 10% Elastane',
        product_style: 'Calf Sleeve',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Calf Sleeves</b> are an excellent addition to any athlete's gear, designed to provide compression to the lower leg. This compression aids in circulation and supports muscle recovery, making them ideal for use during activities like <b>running or post-exercise recovery</b>. Calf Sleeves can cater to individual preferences and needs, enhancing both performance and comfort.
<br/><br/>
In addition to their primary benefits, Calf Sleeves offer extra support for conditions such as varicose veins and shin splints. They can help reduce swelling, making them versatile for various activities and recovery phases. When choosing a calf sleeve, it’s essential to consider fit and comfort to ensure you achieve the desired benefits tailored to your specific requirements
        `
    },
    {
        id: "10",
        name: 'Thigh High (20-30mmHg) DS1114, DS1124 (Black) DS1124, DS1126 (Skin)',
        image: [ 
            {
                id: "img1",
                url: require('../assets/product/socks-thigh-high/closed_toe/1114_1116.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-thigh-high/closed_toe/1114_1116_2.jpg')
            },
            {
                id: "img3",
                url: require('../assets/product/socks-thigh-high/closed_toe/1114_1116_3.jpg')
            }, 
            {
                id: "img4",
                url: require('../assets/product/socks-thigh-high/closed_toe/1124_1126.jpg')
            },
            {
                id: "img5",
                url: require('../assets/product/socks-thigh-high/closed_toe/1124_1126_2.jpg')
            },
            {
                id: "img6",
                url: require('../assets/product/socks-thigh-high/closed_toe/1124_1126_3.jpg')
            },          
        ],
        sku: 'DS1114, DS1124 (Black) DS1124, DS1126 (Skin)',
        color: 'Skin, Black',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Thigh High',
        composition: '70% Nylon 30% Elastane',
        product_style: 'Closed Toe',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Thigh High Socks</b> are the ideal blend of style and comfort, crafted from soft, breathable nylon and elastane for superior stretch and form retention. Designed to stay securely in place, they provide a snug fit without being restrictive, perfect for all-day wear.
<br/><br/>
The playful striped pattern enhances your outfit, boosting confidence whether <b>paired with shorts, skirts, or dresses</b>. Beyond their fashion appeal, these socks offer warmth, making them essential for chilly weather. With antimicrobial properties for freshness, they are versatile for casual outings, cosplay, and festive events, adding a practical yet stylish touch to your wardrobe!
`
    },
    {
        id: "11",
        name: 'Thigh High (20-30mmHg) DS1134, DS1136 (Black) DS1144, DS1146 (Skin)',
        image: [ 
            {
                id: "img1",
                url: require('../assets/product/socks-thigh-high/open-toe/1134_1136.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-thigh-high/open-toe/1134_1136_2.jpg')
            },
            {
                id: "img3",
                url: require('../assets/product/socks-thigh-high/open-toe/1134_1136_3.jpg')
            }, 
            {
                id: "img4",
                url: require('../assets/product/socks-thigh-high/open-toe/1144_1146.jpg')
            },
            {
                id: "img5",
                url: require('../assets/product/socks-thigh-high/open-toe/1144_1146_2.jpg')
            },
            {
                id: "img6",
                url: require('../assets/product/socks-thigh-high/open-toe/1144_1146_3.jpg')
            },          
        ],
        sku: 'DS1134, DS1136 (Black), DS1144, DS1146 (Skin)',
        color: 'Black, Skin',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Thigh High',
        composition: '70% Nylon 30% Elastane',
        product_style: 'Closed Toe',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Thigh High Compression Open Toe</b> Socks offer unique support and versatility, making them ideal for those needing reliable medical-grade compression. With a 20-30 mmHg compression rating, these socks enhance circulation, reduce swelling, and alleviate discomfort related to extended standing or sitting, travel, pregnancy, and post-surgery recovery. Constructed with 70% nylon and 30% elastane, they provide a durable yet comfortable fit.

Features like the <b>silicone grip-lined</b> top band and defined foot ensure secure, adjustment-free wear, while the open-toe design adds <b>versatility</b> for warmer weather and easy inspection. Their targeted support helps relieve issues like<b> varicose veins, edema, and venous insufficiency, promoting overall leg health</b>. Compared to standard compression socks, these standout features offer both functionality and comfort, making them a top choice for anyone seeking quality compression therapy with everyday comfort.
`
    },
    {
        id: "12",
        name: 'Ankle Sports: DS1034, DS1036 (20-30mmHg)',
        image: [ 
            {
                id: "img1",
                url: require('../assets/product/socks-knee-high/ankle-length/1034_1036.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/socks-knee-high/ankle-length/1034_1036_2.jpg')
            }          
        ],
        sku: 'DS1034, DS1036',
        color: '',
        size: 'Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Ankle',
        composition: '70% Nylon, 25% Cotton, 5% Elastane',
        product_style: 'Ankle Sports',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle, and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       Our <b>Ankle Sports</b> socks are designed with a <b>sporty style</b>, combining shades of gray, neon, and black to ensure you look as good as you feel during your activities. This stylish color palette not only enhances your athletic aesthetic but also adds a touch of flair to your workout gear.
<br/><br/>
With <b>enhanced comfort</b> from our innovative padded heel tab, you can say goodbye to discomfort and chafing, ensuring a blister-free experience no matter how long your day or workout lasts. The <b>RunDry moisture-wicking</b> technology keeps your feet cool and dry, while superior arch support provides unmatched stability and reduces fatigue. Crafted for durability, these socks are <b>built to last</b>, making them the perfect choice for the gym or the streets.
`
    }
    ,
    {
        id: "13",
        name: 'Knee Sleeve: DS1074 (20-30mmHg)',
        image: [ 
            {
                id: "img1",
                url: require('../assets/product/knee-sleeve/1074.jpg')
            },            
            {
                id: "img2",
                url: require('../assets/product/knee-sleeve/1074_2.jpg')
            }          
        ],
        sku: 'DS1074',
        color: '',
        size: 'Small, Medium, Large',
        level: '20-30mmHg',
        strength: 'Firm Pressure',
        product_length: 'Ankle',
        composition: '',
        product_style: 'Knee Sleeve',
        used: 'Pronounced Varicose veins. Pronounced leg, ankle and foot swelling. Post surgery. Use when recommended by health care providers.',
        benefits: `
       <b>Compression Knee Sleeves</b> are essential for anyone leading an active lifestyle, providing targeted relief from knee discomfort due to injuries, arthritis, or tendonitis. Crafted from lightweight and breathable fabric, these sleeves ensure a snug fit while delivering moderate compression to enhance support and stability. The innovative design allows for ease of movement, making them <b>ideal for athletes and individuals engaged in physical activities</b>.
<br/><br/>
Incorporating advanced Far Infrared Technology, these knee sleeves convert excess body heat into healing energy, promoting better circulation and reducing inflammation to accelerate recovery. When choosing a knee sleeve, it’s vital to consider factors like comfort, support level, and durability to achieve optimal performance and protection. With proper use, these sleeves alleviate pain and support joint health, enabling you to stay active and perform at your best.
`
    }
]

export class  OurProducts extends React.Component { 
    
    render() {        
        return (
                 
        <Container className="mt-4">
            {PRODUCTS.map((product, index) => {
               return (
                <Row key={product.id} className="border mt-2">   
                    
                    <Row className="fs-5 my-2 mx-3"><b>{product.name}</b> </Row>     
                                   
                    <Row className="">                        
                        { product.image&&product.image.length !==0 ?  (      
                            <Col  className="">                                                          
                            <Carousel className="carousel-dark slide  d-flex justify-content-center" id={"carousel_"+product.id} >                            
                                {product.image.map(((myurl)=>{
                                    
                                    return (
                                        <Carousel.Item key={"CarouselItem"+product.id+myurl.id} className="carousel-item-product">
                                            
                                            <img className="d-block " src={myurl.url}/>
                                            
                                        </Carousel.Item>
                                    )
                                }))}
                            </Carousel>                             
                            </Col>
                            ): <Col sm={1} />                             
                        }
                                            
                        <Col sm={8}>
                            <Row className="fs-6"><b>Product Details:</b> </Row>
                            <Row className="mx-4">                            
                            <ul>
                                <p>
                                    <li key={"sku"+index}>SKU: {product.sku}</li>
                                    {product.color&&product.color!==''?<li key={"color"+index}>Color: {product.color}</li>: null}
                                    {product.size&&product.size!==''?<li key={"size"+index}>Size: {product.size}</li>: null}
                                    {product.level&&product.level!==''?<li key={"level"+index}>Compression Level: {product.level}</li>: null}
                                    {product.strength&&product.strength!==''?<li key={"strength"+index}>Compression Strength: {product.strength}</li>: null}
                                    {product.product_length&&product.product_length!==''?<li key={"product_length"+index}>Length: {product.product_length}</li>: null}
                                    {product.composition&&product.composition!==''?<li key={"composition"+index}>Composition: {product.composition}</li>: null}
                                    {product.product_style&&product.product_style!==''?<li key={"product_style"+index} >Style: {product.product_style}</li>: null}
                                    <li key={"used"+index} >Used For: {product.used}</li>                      
                                </p>
                            </ul>
                            
                            </Row>
                            <Row className="fs-6"><b>Product Benefits:</b> </Row>  
                            <Row className="mx-4"> <p>{Parser(product.benefits)}</p></Row>                                   
                        </Col>
                </Row>                
                </Row>
                
            )})}
        </Container> 
       
        )
    }
}