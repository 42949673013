import React from 'react';
import { Container, Navbar, Nav, } from 'react-bootstrap';

export class  Header extends React.Component { 
    render() {
      return (
        <> 
          <Navbar collapseOnSelect expand="sm" bg="light" variant="light" sticky="top">
            <Container >
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Brand className='align-content-end'>
                      <img src="./images/logo/logo.png" width="300rem"/>
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-center expand me-5'>                
                <Nav>
                    <Nav.Link href="./#/home" className='mx-3 fs-4' bsPrefix="navbar menu-header">Home</Nav.Link>
                    <Nav.Link href="./#/products" className='mx-3 fs-4' bsPrefix="navbar menu-header">Our Products</Nav.Link>
                    <Nav.Link href="./#/contact-us" className='mx-3 fs-4' bsPrefix="navbar menu-header me-5">Contact Us</Nav.Link>         
                </Nav>         
              </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
        
      );
    }
}