import React from 'react';
import {  Container, Carousel, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export class  Home extends React.Component { 
    constructor(props) {
      super(props);
      this.state = { width: 0, height: 0 };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.state.small = 768;
      this.state.medium = 992;
    }
    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {     
      const heading1 = <Col className="card mb-4 mx-2 box-shadow home-box">                  
                          <img className="card-img-top mt-4" src="./images/home/heading_1.jpg" alt="Generic placeholder image"></img>
                          <div className="card-body">
                            <p>Cushion Sports compression socks are designed to provide excellent support and improve blood circulation during physical activities. With non-slip soles and added cushioning on the bottom, they increase grip and reduce foot pressure, making them ideal for active use. Extra padding at the heel and ball of the foot adds comfort and protection.
                            </p>
                            <p><a type="button" href="./#/products" className="btn btn-outline-primary">View details &raquo;</a></p>
                          </div> 
                       </Col>
      const heading2 = <Col className="card mb-4 mx-2 box-shadow home-box">                  
                          <img className="card-img-top  mt-4" src="./images/home/heading_2.jpg" alt="Generic placeholder image"></img>
                          <div className="card-body">      
                            <p>Compression socks with a Zipper style are designed to provide the benefits of compression while being easier to put on and take off. These socks typically offer graduated compression that is tighter at the ankle and gradually decreases up the leg, which can help improve circulation, reduce swelling, and alleviate pain.</p>
                            <p><a type="button" href="./#/products" className="btn btn-outline-primary">View details &raquo;</a></p>
                          </div>
                        </Col> 
      const heading3 = <Col className="card mb-4 mx-2 box-shadow home-box">                  
                        <img className="card-img-top mt-4" src="./images/home/heading_3.jpg" alt="Generic placeholder image"></img>
                        <div className="card-body"> 
                          <p>Crew Sports Compression Socks offer a range of benefits designed specifically for athletes. The durable materials wick away sweat and dry quickly, keeping your feet comfortable and cool even during intense activities. With a new Locked-In Fit design, these socks stay securely on your heel, eliminating the need for constant adjustments, while medium cushioning provides additional comfort and protection throughout your workouts.</p>
                          <p><a type="button" href="./#/products" className="btn btn-outline-primary">View details &raquo;</a></p>
                        </div> 
                      </Col>
      const heading4 = <Col className="card mb-4 mx-2 box-shadow home-box">                  
                        <img className="card-img-top mt-4" src="./images/home/heading_4.jpg" alt="Generic placeholder image"></img>
                        <div className="card-body">     
                          <p>Thigh High Socks are the ideal blend of style and comfort, crafted from soft, breathable nylon and elastane for superior stretch and form retention. Designed to stay securely in place, they provide a snug fit without being restrictive, perfect for all-day wear.
                          </p>                      
                          <p><a type="button" href="./#/products" className="btn btn-outline-primary">View details &raquo;</a></p>
                        </div>                        
                      </Col>
      console.log(this.state.width)
      let heading 
      if(this.state.width > this.state.medium) {
        heading = <Row>{heading1}{heading2}{heading3}{heading4}</Row>        
      } else if(this.state.width > this.state.small)  {
        heading = <><Row>{heading1}{heading2}</Row><Row>{heading3}{heading4}</Row></>
      } else {
        heading = <><Row>{heading1}</Row><Row>{heading2}</Row><Row>{heading3}</Row><Row>{heading4}</Row></>
      }
      return (
        <>
          {
            this.state.width > this.state.small &&
            <Carousel className="carousel-banner">
                  <Carousel.Item className='carousel-item-banner'>
                      <img className="d-block w-100" src="./images/home/banner_1.jpg"/>                    
                  </Carousel.Item>
                  <Carousel.Item className='carousel-item-banner'>
                      <img className="d-block w-100" src="./images/home/banner_2.jpg"/>
                  </Carousel.Item>                
            </Carousel>
          }
          <Container className = "container">
            <Row>
              <Col>                
              <p> At <b>Compression Plus</b>, we’re a friendly family-run business dedicated to helping you <b>live a healthier, more active life</b>. We understand how tough it can be to balance family, work, and staying fit, so we offer high-quality compression products that make it easier.</p>                
              <p> Our range of comfortable socks comes in various styles and materials, perfect for everyone, whether you love sports or just want to feel good. With our focus on customer satisfaction, we strive to deliver the best solutions to meet your needs</p>
              <p>We’re here to support your wellness journey every step of the way! Your health matters to us, and we’re committed to providing you with effective and proven products to <b>enhance your well-being</b>.</p>
              <p><b>Elevate Your Comfort and Recovery with Compression Plus: Discover Our Moderate and Firm Compression Socks!</b></p>
               
              </Col>
            </Row>
            { heading}
                          
          </Container>          
           </>
        );
    }
}