import React from "react";
import {  Col, Container,  Row,  } from "react-bootstrap";

function iframe() {
    return {
        __html: `
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1054.8622464557925!2d-113.96530352125208!3d51.03647914711338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717b0838a1c483%3A0x6b440a692d564c77!2sCompression%20Plus!5e0!3m2!1sen!2sca!4v1732271819932!5m2!1sen!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    `
    }
}
export class  Contact extends React.Component { 
    render() {        
        return (
        <>          
            <Container className="mt-4 lead">
            <Row>
                <Col sm={2} md={2}></Col>
                <Col sm={8} md={8}>
                <h2>Contact</h2>
                <p   className="fs-4"> Please make your appointment request at <b>587-968-1070</b>.<br/> </p>              
                
                <div dangerouslySetInnerHTML={iframe()} />   
                
                </Col>
                <Col sm={2} md={2}></Col>
            </Row>
            </Container>
       
       
        </>    
        )
    }
}